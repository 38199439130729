import {
  formatDistanceToNowStrict,
  parseISO,
  differenceInMinutes,
  differenceInHours,
} from "date-fns";
import {
  Badge,
  Flex,
  Icon,
  Tag,
  TagRightIcon,
  TagLabel,
  Text,
  useConst,
} from "@chakra-ui/react";

import { CalendarIcon as ArrivalIcon } from "@chakra-ui/icons";
import {
  MdOutlineNightsStay as NightsIcon,
  MdGroup as CapacityIcon,
} from "react-icons/md";
import { FaCampground as SitesIcon } from "react-icons/fa";

import { maybePluralizeNoun } from "~/utils/words";
import { mapDbToClientType } from "~/utils/sites";
import { config } from "~/utils/constants";

import {
  includeNightsText,
  includeCapacityText,
  arrivalText,
  nightsText,
  sitesText,
  capacityText,
} from "~/utils/availabilityRequest";

import { maxRVLengthOptions } from "~/utils/maps";

import { useOptionalUser, useUserSubscriptionValue } from "~/hooks";

import {
  AccessibleIcon,
  WaterIcon,
  SewerIcon,
  ElectricIcon,
  PullThroughIcon,
  TentOnlyIcon,
  DoubleSiteIcon,
} from "./Icons";
import { capitalize } from "lodash-es";

function RightIconTag({ Icon, text, ...props }) {
  return (
    <Tag variant="subtle" size="sm" {...props}>
      <TagLabel>{text}</TagLabel>
      <TagRightIcon fontSize="md" as={Icon} />
    </Tag>
  );
}

export function SiteFilterBadge({ site, ...props }) {
  return (
    <>
      {site.isAccessible && (
        <RightIconTag {...props} Icon={AccessibleIcon} text="Accessible" />
      )}
      {(site.isTentOnly || site.type === "tent") && (
        <RightIconTag {...props} Icon={TentOnlyIcon} text="Tents Only" />
      )}
      {site.isDoubleSite && (
        <RightIconTag {...props} Icon={DoubleSiteIcon} text="Double Site" />
      )}
      {site.maxRVLength && (
        <Tag size="sm" {...props}>{`${
          maxRVLengthOptions.find((option) => option.value == site.maxRVLength)
            .label
        }`}</Tag>
      )}
      {site.hasElectric ? (
        site.maxElectric ? (
          <RightIconTag
            {...props}
            Icon={ElectricIcon}
            text={`${site.maxElectric} Amps`}
          />
        ) : (
          <RightIconTag {...props} Icon={ElectricIcon} text="Electric" />
        )
      ) : null}
      {site.hasWater && (
        <RightIconTag {...props} Icon={WaterIcon} text="Water" />
      )}
      {site.hasSewer && (
        <RightIconTag {...props} Icon={SewerIcon} text="Sewer" />
      )}
      {site.isPullThrough && (
        <RightIconTag {...props} Icon={PullThroughIcon} text="Pull-Through" />
      )}
    </>
  );
}

export function SiteTypeBadge({ type, ...badgeProps }) {
  const badgeText = mapDbToClientType[type];
  return (
    <Tag size="sm" {...badgeProps}>
      {badgeText}
    </Tag>
  );
}

export function AvailabilityMatchAvailableBadge({ count, ...badgeProps }) {
  if (count === 0) {
    return null;
  }

  let badgeText, colorScheme;

  // if (count < config.MAX_AVAILABILITY_MATCHES) {
  badgeText = `${count.toLocaleString()} open`;
  colorScheme = "green";
  // } else {
  //   badgeText = `${config.MAX_AVAILABILITY_MATCHES}+ open`;
  //   colorScheme = "green";
  // }

  return (
    <Badge colorScheme={colorScheme} {...badgeProps}>
      {badgeText}
    </Badge>
  );
}

export function AvailabilityMatchAvailableAtBadge({
  availabilityMatch,
  ...badgeProps
}) {
  const now = useConst(new Date());
  const availableAt = formatDistanceToNowStrict(
    parseISO(availabilityMatch.availableAt),
    {
      addSuffix: true,
    },
  );

  const badgeText = `opened ${availableAt}`;
  const colorScheme = "green";

  return (
    <Badge colorScheme={colorScheme} {...badgeProps}>
      {badgeText}
    </Badge>
  );
}

export function AvailabilityMatchUnavailableAtBadge({
  availabilityMatch,
  ...badgeProps
}) {
  const now = useConst(new Date());
  const unavailableAt = formatDistanceToNowStrict(
    parseISO(availabilityMatch.unavailableAt),
    {
      addSuffix: true,
    },
  );

  const badgeText = `closed ${unavailableAt}`;
  const colorScheme = "red";

  return (
    <Badge colorScheme={colorScheme} {...badgeProps}>
      {badgeText}
    </Badge>
  );
}

export function AvailabilityRequestCheckedAtBadge({
  availabilityRequest,
  prefix = "scanned ",
  ...badgeProps
}) {
  const now = useConst(new Date());
  const checkedCount = availabilityRequest.checkedCount;
  let badgeText, colorScheme;

  if (checkedCount === 0) {
    colorScheme = "orange";
    badgeText = "awaiting first scan";
  } else {
    badgeText = prefix;
    const checkedAt = parseISO(availabilityRequest.checkedAt);

    if (differenceInMinutes(now, checkedAt) <= 60) {
      colorScheme = "green";
      badgeText += formatDistanceToNowStrict(checkedAt, {
        addSuffix: true,
      });
    } else if (differenceInHours(now, checkedAt) <= 4) {
      colorScheme = "orange";
      badgeText += formatDistanceToNowStrict(checkedAt, {
        addSuffix: true,
      });
    } else {
      colorScheme = "red";
      badgeText += formatDistanceToNowStrict(checkedAt, {
        addSuffix: true,
      });
    }
  }

  return (
    <Badge
      colorScheme={colorScheme}
      {...badgeProps}
      suppressHydrationWarning={true}
    >
      {badgeText}
    </Badge>
  );
}

export function AvailabilityRequestCheckedCountBadge({
  availabilityRequest,
  ...badgeProps
}) {
  const checkedCount = availabilityRequest.checkedCount;
  const badgeText = maybePluralizeNoun(checkedCount, "scan");
  return <Badge {...badgeProps}>{badgeText}</Badge>;
}

export function AvailabilityRequestStatusBadge({
  availabilityRequest,
  ...badgeProps
}) {
  const badgeText = availabilityRequest.status;
  let colorScheme;

  if (badgeText === "active") {
    colorScheme = "green";
  } else if (badgeText === "paused") {
    colorScheme = "orange";
  } else if (badgeText === "cancelled") {
    colorScheme = "red";
  } else if (badgeText === "expired") {
    colorScheme = "blue";
  } else {
    colorScheme = "gray";
  }

  return (
    <Badge
      colorScheme={colorScheme}
      {...badgeProps}
      data-testid="availability-request-status-badge"
    >
      {badgeText}
    </Badge>
  );
}

export function AvailabilityMatchStatusBadge({
  availabilityMatch,
  availabilityRequest,
  ...badgeProps
}) {
  let badgeText, colorScheme;

  if (availabilityMatch.available) {
    const status =
      availabilityRequest?.status ||
      availabilityMatch?.availabilityRequest?.status;

    if (status === "cancelled" || status === "paused") {
      badgeText = "unknown";
      colorScheme = "orange";
    } else if (availabilityMatch.duplicateFromId) {
      badgeText = "re-open";
      colorScheme = "orange";
    } else {
      badgeText = "open";
      colorScheme = "green";
    }
  } else {
    colorScheme = "red";
    badgeText = "closed";
  }

  return (
    <Badge colorScheme={colorScheme} {...badgeProps}>
      {badgeText}
    </Badge>
  );
}

export function AvailabilityRequestArrivalBadge({
  availabilityRequest,
  ...props
}) {
  return (
    <Flex alignItems="center" {...props}>
      <Icon as={ArrivalIcon} boxSize={3} mr={1} />
      <Text>{arrivalText(availabilityRequest)}</Text>
    </Flex>
  );
}

export function AvailabilityRequestNightsBadge({
  availabilityRequest,
  ...props
}) {
  if (!includeNightsText(availabilityRequest)) {
    return null;
  }

  return (
    <Flex alignItems="center" {...props}>
      <Icon as={NightsIcon} boxSize={4} />
      <Text>
        <Text as="span">{nightsText(availabilityRequest)}</Text>
      </Text>
    </Flex>
  );
}

export function AvailabilityRequestSitesBadge({
  availabilityRequest,
  ...props
}) {
  return (
    <Flex alignItems="center" {...props}>
      <Icon as={SitesIcon} boxSize={4} mr={1} />
      <Text>
        <Text as="span">{sitesText(availabilityRequest)}</Text>
      </Text>
    </Flex>
  );
}

export function AvailabilityRequestCapacityBadge({
  availabilityRequest,
  ...props
}) {
  if (!includeCapacityText(availabilityRequest)) {
    return null;
  }

  return (
    <Flex alignItems="center" {...props}>
      <Icon as={CapacityIcon} boxSize={5} mr={1} />
      <Text>
        with <Text as="span">{capacityText(availabilityRequest)}</Text>
      </Text>
    </Flex>
  );
}

export function SubscriptionBadge({ ...badgeProps }) {
  const defaultProps = useUserSubscriptionValue({
    none: () => null,
    trial: () => ({
      colorScheme: "orange",
      children: "trial",
    }),
    active_flexible: () => ({
      colorScheme: "blue",
      children: "legacy",
    }),
    active_unlimited: () => ({
      colorScheme: "blue",
      children: "legacy",
    }),
    active: (user) => ({
      colorScheme: "green",
      children: user.subscription.status.replace("active_", ""),
    }),
    active_pro: (user) => ({
      colorScheme: "green",
      children: "priority",
    }),
  });

  if (!defaultProps) {
    return null;
  }

  return <Badge {...defaultProps} {...badgeProps} />;
}

export function NewFeatureBadge({ ...badgeProps }) {
  return (
    <Badge colorScheme="green" {...badgeProps}>
      new
    </Badge>
  );
}
